import {PageUrlInfo, UrlListItem} from '../Models/Types'
import {SignedUpSignboard, SignedUpState} from './SignedUpSignboardDTO'
import {ListUtility} from '../Utils/ListUtility'
import {Optional} from '../../Common/TypeHelper'
import {MBMemberError} from '../Common/MBMemberError'

const MembershipStatuses = ['signedUp', 'notSignedUp', 'unavailable'] as const
export type MembershipStatus = (typeof MembershipStatuses)[number]

export type SignedUpBucketInfoJSON = {
  id: number
  bucketKey: string
  bucketName: string
  urlList: UrlListItem[]
  membershipStatus: string
} & Pick<SignedUpSignboard, 'signedUpStates'>

export type SignedUpBucketInfo = {
  id: number
  bucketKey: string
  bucketName: string
  bucketTopPageInfo: PageUrlInfo
  bucketGuidancePageUrl: string
  membershipStatus: MembershipStatus
} & Pick<SignedUpSignboard, 'signedUpStates'>

export class SignedUpBucketInfoDTO implements SignedUpBucketInfo {
  id: number
  bucketKey: string
  bucketName: string
  bucketTopPageInfo: PageUrlInfo
  bucketGuidancePageUrl: string
  logoImgUrl: string
  membershipStatus: MembershipStatus
  signedUpStates: SignedUpState[]

  constructor(
    id: number,
    bucketKey: string,
    bucketName: string,
    bucketTopPageInfo: PageUrlInfo,
    bucketGuidancePageUrl: string,
    logoImgUrl: string,
    membershipStatus: MembershipStatus,
    signedUpStates: SignedUpState[],
  ) {
    this.id = id
    this.bucketKey = bucketKey
    this.bucketName = bucketName
    this.bucketTopPageInfo = bucketTopPageInfo
    this.bucketGuidancePageUrl = bucketGuidancePageUrl
    this.logoImgUrl = logoImgUrl
    this.membershipStatus = membershipStatus
    this.signedUpStates = signedUpStates
  }

  static fromJSON(jsonObject: SignedUpBucketInfoJSON): SignedUpBucketInfoDTO {
    const filteredBucketTopPageUrl = ListUtility.findOne<UrlListItem>(
      jsonObject.urlList || [],
      item => item.type === 'bucketTopPageUrl',
    )
    const bucketTopPageInfo: PageUrlInfo = {
      url: filteredBucketTopPageUrl?.url,
      name: filteredBucketTopPageUrl?.name ?? '',
    }

    const filteredBucketGuidancePageUrl = ListUtility.findOne<UrlListItem>(
      jsonObject.urlList || [],
      item => item.type === 'bucketGuidancePageUrl',
    )
    const bucketGuidancePageUrl: Optional<string> = filteredBucketGuidancePageUrl?.url
    const filteredLogoImgUrl = ListUtility.findOne<UrlListItem>(
      jsonObject.urlList || [],
      item => item.type === 'logoImg',
    )
    const logoImgUrl: Optional<string> = filteredLogoImgUrl?.url

    if (!isMembershipStatus(jsonObject.membershipStatus)) throw new MBMemberError('SystemError')

    return new SignedUpBucketInfoDTO(
      jsonObject.id,
      jsonObject.bucketKey,
      jsonObject.bucketName,
      bucketTopPageInfo,
      bucketGuidancePageUrl || '',
      logoImgUrl || '',
      jsonObject.membershipStatus,
      jsonObject.signedUpStates,
    )
  }
}

const isMembershipStatus = (status: string): status is MembershipStatus => {
  return MembershipStatuses.includes(status as MembershipStatus)
}

import {Nullable} from '../Common/TypeHelper'

export type CommonResponseJSON<DataType> = {
  status: CommonResponseStatusType
  errorMessageList: string[]
  data: DataType
}

export class CommonResponseDTO<DataType> {
  status: CommonResponseStatusType
  errorMessageList: string[]
  data: DataType

  constructor(status: CommonResponseStatusType, data: DataType, errorMessageList: string[]) {
    this.status = status
    this.data = data
    this.errorMessageList = errorMessageList
  }

  static NO_CONVERT<J>(jsonDataPart: J): J {
    return jsonDataPart
  }

  static ofNullData(responseJson: CommonResponseJSON<null>): CommonResponseDTO<null> {
    return new CommonResponseDTO(responseJson.status, null, responseJson.errorMessageList)
  }

  static from<R, J>(
    responseJson: CommonResponseJSON<Nullable<J>>,
    converter: (jsonDataPart: J) => R,
  ): CommonResponseDTO<Nullable<R>> {
    const convertData = responseJson != null
      ? responseJson.data && converter(responseJson.data)
      : null
    return new CommonResponseDTO(
      responseJson.status,
      convertData,
      responseJson.errorMessageList,
    )
  }

  static fromArray<R, J>(
    responseJson: CommonResponseJSON<Nullable<J[]>>,
    converter: (jsonDataPart: J) => R,
  ): CommonResponseDTO<R[]> {
    return new CommonResponseDTO(
      responseJson.status,
      (responseJson.data == null) ? [] : (responseJson.data).map((item: J) => converter(item)),
      responseJson.errorMessageList,
    )
  }
}

export const CommonResponseStatus = {
  Ok: 'OK',
  Updated: 'Updated',
  Accepted: 'Accepted',
  NotFound: 'Not Found',
  Conflict: 'Conflict',
  Forbidden: 'Forbidden',
  BadRequest: 'Bad Request',
  Created: 'Created',
  InternalServerError: 'Internal Server Error',
  InvalidRequest: 'Invalid Request',
  NoAuthority: 'No Authority',
  InvalidCreditCard: 'Invalid Credit Card',
  ThreeDSAuthNotSupportedCreditCard: '3DS Auth Not Supported Credit Card',
  ThreeDSAuthError: '3DS Auth Error',
  PaymentSystemError: 'Payment System Error',
  Processing: 'Processing',
  OvertimeError: 'Overtime Error',
  BucketUnsupported: 'Bucket Unsupported',
  CannotOperate: 'Cannot Operate',
  MailSendError: 'Mail Send Error',
  CreditCardRegistrationRestricted: 'Credit Card Registration Restricted',
  InvalidStatusTransition: 'Invalid Status Transition',
  UnknownError: 'Unknown Error', // 実際にサーバーから返ることはない。テストコードでのみ参照される。
  UnknownStatus: 'Unknown Status', // 実際にサーバーから返ることはない。テストコードでのみ参照される。
  FatalError: 'Fatal Error', // 実際にサーバーから返ることはない。テストコードでのみ参照される。
  ErrorStatus: 'Error status', // 実際にサーバーから返ることはない。テストコードでのみ参照される。
  _: '', // 実際にサーバーから返ることはない。テストコードでのみ参照される。
} as const

export type CommonResponseStatusType =
  typeof CommonResponseStatus[keyof typeof CommonResponseStatus]

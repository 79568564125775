import {css} from '@emotion/react'

export namespace Color {
  export const Primary500 = '#0C606A'
  export const Primary400 = '#15A6B7'
  export const Primary100 = '#E8F5F7'

  export const NewGrey900 = '#2B2B2B'
  export const NewGrey800 = '#474747'
  export const NewGrey700 = '#636363'
  export const NewGrey600 = '#808080'
  export const NewGrey400 = '#BABABA'
  export const NewGrey300 = '#CCCCCC'
  export const NewGrey200 = '#EBEBEB'
  export const NewGrey150 = '#F0F0F0'
  export const NewGrey100 = '#F5F5F5'
  export const NewGrey50 = '#FAFAFA'

  export const White = '#FFFFFF'
  export const Accent700 = '#93102E'
  export const Accent600 = '#C1153D'
  export const Focus = '#3399FF'
  export const CheckBoxOutlineBlankColor = '#C4C9CF'
  export const LinkButton = '#0000EE'
}

export const DefaultLineHeight = 1.5

export namespace Typography {
  export const Bold28 = css`
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
  `

  export const Bold24 = css`
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
  `

  export const Bold20 = css`
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
  `

  export const Bold18 = css`
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
  `

  export const Bold16 = css`
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
  `

  export const Bold14 = css`
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
  `

  export const Bold12 = css`
    font-weight: bold;
    font-size: 12px;
    line-height: 1;
  `

  export const Regular16 = css`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
  `

  export const Regular16_multiline = css`
    ${Regular16};
    line-height: ${DefaultLineHeight};
  `

  export const Bold14_multiline = css`
    ${Bold14};
    line-height: ${DefaultLineHeight};
  `

  export const Regular14 = css`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
  `

  export const Regular14_multiline = css`
    ${Regular14};
    line-height: ${DefaultLineHeight};
  `

  export const Regular14_link = css`
    ${Regular14};
    text-decoration-line: underline;
  `

  export const Regular12 = css`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1;
  `

  export const Regular12_link = css`
    ${Regular12};
    text-decoration-line: underline;
  `

  export const Bold10 = css`
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 1;
  `

  export const Regular10 = css`
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 1;
  `
}

export namespace Size {
  export const HeaderHeight = 50
  export const HeaderWidthOpen = 200
  export const HeaderWidthClose = 50
  export const MainContainerPaddingTop = 24
  export const MainContainerSidePadding = 24
  export const MainContainerPaddingBottom = 96
  export const ResponsiveMainContainerPaddingTop = 12
  export const ResponsiveMainContainerSidePadding = 4
  export const ResponsiveMainContainerPaddingBottom = 32
  export const BackLinkButtonHeight = 12
  export const PageHeaderMarginTop = 28
  export const PageHeaderMarginBottom = 24
  export const ArticleContainerMarginTop = 12
  export const ArticleContainerSidePadding = 8
  export const PrimaryButtonHeight = 48
  export const PrimaryButtonWidth = 192
  export const ResponsiveMiniButtonHeight = 40
  export const ResponsiveMiniButtonWidth = 72
  export const AttentionFormWidth = 488
  export const AttentionButtonWidth = 270
  export const MiniButtonHeight = 32
  export const MiniButtonWidth = 96
}

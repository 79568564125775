import styled from '@emotion/styled'
import {Box} from '@mui/material'
import {Color, Size} from '../Styles'
import React from 'react'
import {Copyright} from './Copyrights'
import {theme} from '../../AdminTheme'

export const PageContentMinWidth =
  theme.membucket.size.largeTableWidth +
  (Size.MainContainerSidePadding + Size.ArticleContainerSidePadding) * 2

export const RowFlexContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ResponsiveRowFlexContainer = styled(RowFlexContainer)`
  @media (max-width: ${PageContentMinWidth}px) {
    flex-direction: column;
  }
`
// 要素を左右寄せなど範囲いっぱいに配置したい場合
export const RowFlexContainerSpaceBetween = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const ColumnFlexContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
`

type MainContainerProps = {
  children: React.ReactNode
  testId?: string
}
const StyledAdminMain = styled.main`
  width: 100%;
  min-width: ${`${PageContentMinWidth}px`};
  height: 100%;
  flex: 1 1 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: ${Size.MainContainerPaddingTop}px ${Size.MainContainerSidePadding}px ${Size.MainContainerPaddingBottom}px;
  background: ${Color.NewGrey100};
`
export const MainContainer: React.VFC<MainContainerProps> = (props) => {
  return (
    <StyledAdminMain
      id={'membucket-admin-main-container'}
      data-testid={props.testId}
    >
      {props.children}
    </StyledAdminMain>
  )
}
const ResponsiveAdminMain = styled(StyledAdminMain)`
  @media (max-width: ${PageContentMinWidth}px) {
    min-width: 0;
    padding: ${Size.ResponsiveMainContainerPaddingTop}px ${Size.ResponsiveMainContainerSidePadding}px
    ${Size.ResponsiveMainContainerPaddingBottom}px;
  }
`
export const ResponsiveMainContainer: React.VFC<MainContainerProps> = (props) => {
  return (
    <ResponsiveAdminMain
      id={'membucket-admin-main-container'}
      data-testid={props.testId}
    >
      {props.children}
    </ResponsiveAdminMain>
  )
}

const StyledAdminMainWithCopyright = styled(StyledAdminMain)`
  padding: 0;
`
export const MainContainerWithCopyright: React.VFC<MainContainerProps> = (props) => {
  return (
    <StyledAdminMainWithCopyright data-testid={props.testId}>
      <Box
        sx={{
          padding: `${Size.MainContainerPaddingTop}px ${Size.MainContainerSidePadding}px
          ${Size.MainContainerPaddingBottom}px`,
        }}
      >
        {props.children}
      </Box>
      <Box mt={'auto'} mb={2} textAlign={'center'}>
        <Copyright color={Color.NewGrey900}/>
      </Box>
    </StyledAdminMainWithCopyright>
  )
}
const ResponsiveStyledAdminMainWithCopyright = styled(ResponsiveAdminMain)`
  padding: 0;
  @media (max-width: ${PageContentMinWidth}px) {
    padding: 0;
  }
`
const ResponsiveMainContainerMainContentBox = styled(Box)`
  padding: ${Size.MainContainerPaddingTop}px ${Size.MainContainerSidePadding}px ${Size.MainContainerPaddingBottom}px;
  @media (max-width: ${PageContentMinWidth}px) {
    padding: ${Size.ResponsiveMainContainerPaddingTop}px ${Size.ResponsiveMainContainerSidePadding}px
    ${Size.ResponsiveMainContainerPaddingBottom}px;
  }
`
export const ResponsiveMainContainerWithCopyright: React.VFC<MainContainerProps> = (props) => {
  return (
    <ResponsiveStyledAdminMainWithCopyright data-testid={props.testId}>
      <ResponsiveMainContainerMainContentBox>
        {props.children}
      </ResponsiveMainContainerMainContentBox>
      <Box mt={'auto'} mb={2} textAlign={'center'}>
        <Copyright color={Color.NewGrey900}/>
      </Box>
    </ResponsiveStyledAdminMainWithCopyright>
  )
}

export const ArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${Size.ArticleContainerMarginTop}px;
  padding: 0 ${Size.ArticleContainerSidePadding}px;
`

export const FormArea = styled(Box)`
  padding: 48px 0;
  background: ${Color.NewGrey200};
  width: 100%;
  max-width: ${(props): number => props.theme.membucket.size.formWidth}px;
`
export const ResponsiveFormArea = styled(FormArea)`
  @media (max-width: ${PageContentMinWidth}px) {
    padding-right: 8px;
    padding-left: 8px;
  }
`

export const LargeFormArea = styled(Box)`
  padding: 32px 0;
  background: ${Color.NewGrey200};
  width: 100%;
  max-width: ${(props): number => props.theme.membucket.size.largeFormWidth}px;
`

export const AttentionFormArea = styled(Box)`
  padding: 24px 16px;
  background: ${Color.NewGrey200};
  width: ${Size.AttentionFormWidth}px;
`

export const ButtonArea = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
`
export const ResponsiveButtonArea = styled(ButtonArea)`
  @media (max-width: ${PageContentMinWidth}px) {
    margin-top: 16px;
  }
`

export const ErrorMessageArea = styled(Box)`
  margin-bottom: 24px;
`
